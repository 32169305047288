<template>
  <v-layout>
    <v-app>
      <v-container
        :fluid="true"
        class="ma-0 pa-0"
      >
        <v-row
          class="header-notification d-none d-md-block"
          no-gutters
        >
          <v-col cols="12 pa-5 pa-mb-0">
            STEIGERN SIE IHREN UMSATZ! OPTIMIEREN SIE IHR GESCHÄFT UND PROFITIEREN SIE VON UNSERER INNOVATIVEN LÖSUNG.
            <a href="/auth/register">JETZT KOSTENLOS TESTEN!</a>
          </v-col>
        </v-row>

        <v-container
          :fluid="false"
          class="pb-0 mb-3"
          v-if="!slots.hero"
        >
          <v-row>
            <v-col cols="12">
              <Header :links="links" />
            </v-col>
          </v-row>
        </v-container>
        <v-divider v-if="!slots.hero" />
      </v-container>
      <v-container
        class="pa-0 ma-0"
        :fluid="true"
        v-if="slots.hero"
      >
        <div class="hero-section">
          <v-container
            :fluid="false"
            class="pb-0 mb-3"
          >
            <v-row>
              <v-col cols="12">
                <Header
                  :useWhite="true"
                  :links="links"
                />
              </v-col>
            </v-row>
          </v-container>
          <slot name="hero" />
        </div>
      </v-container>
      <v-container :fluid="false">
        <v-row>
          <v-col
            cols="12"
            class="mt-0 mt-md-0"
          >
            <slot />
          </v-col>
        </v-row>
      </v-container>
      <v-container
        :fluid="true"
        class="ma-0 pa-5 public-footer"
      >
        <v-container :fluid="false">
          <v-row :no-gutters="true">
            <v-col cols="12">
              <FooterPublic />
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-app>
  </v-layout>

  <CookieDialog />

  <v-snackbar
    v-for="snackbar in snackbars"
    v-model="snackbar.show"
    :color="snackbar.color"
    :timeout="5000"
    :top="true"
  >
    {{ snackbar.message }}
  </v-snackbar>
</template>

<script setup lang="ts">
import Header from '@/components/public/Header.vue'
import FooterPublic from '@/components/public/FooterPublic.vue'
import { usePageStore } from '~/store/pageStore';
import { useSlots } from 'vue'
import CookieDialog from '~/components/dialogs/CookieDialog.vue';

const pageStore = usePageStore();
const { snackbars } = storeToRefs(pageStore);
const slots = useSlots();

const links = [
  {
    id: 1,
    title: 'Home',
    to: '/',
    exact: true,
    icon: 'ri-home-4-line',
  },
  {
    id: 2,
    title: 'Anwendungen',
    to: '/usecases',
    exact: true,
    icon: 'ri-apps-2-line',
  },
  // {
  //   id: 2,
  //   title: 'Katalog',
  //   to: '/listings',
  //   exact: false,
  //   icon: 'ri-shopping-bag-line',
  // },
  // {
  //   id: 3,
  //   title: 'Kontakt',
  //   to: '/contact',
  //   exact: false,
  //   icon: 'ri-mail-line',
  // },
  // {
  //   id: 4,
  //   title: 'Login',
  //   to: '/auth/login',
  //   exact: false,
  //   icon: 'ri-login-box-line',
  // },
];
</script>

<style scoped lang="scss">
.header-notification a {
  color: #fff;
  border-bottom: 1px solid #fff;
}

.hero-section {
  background-image: url('/public/images/terminplanung-online-hero.jpg');
  background-size: cover;
  background-position: center;
  color: white;
}
</style>